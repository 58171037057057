import React from "react"
import { connect } from "react-redux"
import {
  getCover,
  getFile,
  getPhotos,
  getQuality,
} from "../../../../helpers/helper.rendering"
import IconGrid from "../../../../images/item-grid.png"
const Grid = ({ development }) => {
  const makeGrid = development => {
    if (development?.id) {
      if (development?.photos?.length > 3) {
        let grid = [
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
          { image: "", text: "" },
        ]
        let text_split = development?.description
          ?.split("\n")
          .filter(e => e.length > 10)
        let images = getPhotos(development).slice(
          2,
          getPhotos(development).length
        )
        for (let index = 0; index < getPhotos(development).length; index++) {
          if (images.length > index) {
            grid[index].image === ""
              ? (grid[index].image = images[index])
              : null
          }
        }

        for (let index = 0; index < getPhotos(development).length; index++) {
          if (grid[index].image !== "" && text_split[index]) {
            grid[index].text += `${text_split[index]} \n`
            if (grid[index].text.length > 370 * 1.6) {
              grid[index].text = grid[index].text.slice(0, 370 * 1.6) + "..."
            }
          }
        }
        return grid
      }
      return []
    }
  }

  return (
    <section className="grid-seleccion-detail" id="caracteristicas-scroll">
      <div className="container-fluid">
        <h2 className="mb-5 pb-lg-5 text-center">
          {development.publication_title}
        </h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {makeGrid(development)?.length > 3 && (
              <div class="grid-container">
                {makeGrid(development)
                  .filter(element => element.text.length > 10)
                  .map(({ image, text }) => (
                    <div class="grid-item">
                      <img
                        src={getQuality(image)}
                        alt="Imagen del emprendimiento en una grilla"
                      />
                      <div className="d-flex content">
                        <img
                          className="icon"
                          src={IconGrid}
                          alt="Icono decorativo de una grilla"
                        />
                        <p>
                          {text.split("\n").map(element => (
                            <>
                              {element}
                              {element.length > 0 && <br />}
                            </>
                          ))}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {development?.files?.length > 0 && (
              <div
                className="files d-flex flex-column flex-wrap flex-lg-row align-items-center"
                id="descargas-scroll"
              >
                <h3 className="me-lg-4 mb-0 me-2">Descargar:</h3>
                {development?.files?.map(file => (
                  <a
                    target="_blank"
                    onClick={() =>
                      downloadImage(
                        file.file,
                        getFile(file).name,
                        getFile(file).ext
                      )
                    }
                    className={
                      "btn btn-black border me-3 d-lg-inline-flex d-flex mb-0 align-items-center justify-content-center justify-content-lg-start"
                    }
                    href={file.file}
                    download
                  >
                    <i className={`order-lg-1 order-2 icon-download-solid`}></i>
                    <div className="ms-lg-2 me-3 me-lg-0 d-flex order-lg-2 order-1 name-file">
                      {getFile(file).name}.{getFile(file).ext}
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
  }),
  null
)(Grid)
